import { Metrics } from './metrics';

// only expected to be unknown in jest
let packageName = 'unknown';
let templateBasename = 'unknown';
try {
  var _bender$templateBasen, _window;
  templateBasename = // eslint-disable-next-line hubspot-dev/hubspot-is-special
  (_bender$templateBasen = (_window = window) === null || _window === void 0 || (_window = _window.hubspot) === null || _window === void 0 || (_window = _window.bender) === null || _window === void 0 ? void 0 : _window.templateBasename) !== null && _bender$templateBasen !== void 0 ? _bender$templateBasen : 'unknown';
} catch (e) {
  /* ignore */
}
try {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  packageName = require('current-package-loader!').name;
} catch (e) {
  // ignore
}
let hasSeenReady = false;
const baseDimensions = {
  packageName,
  templateBasename
};
function createMetrics() {
  const currentDimensions = Object.assign({}, baseDimensions, {
    hasSeenReady: `${hasSeenReady}`
  });
  return {
    true: Metrics.counter('foundations-theme-ready', Object.assign({}, currentDimensions, {
      result: 'true'
    })),
    false: Metrics.counter('foundations-theme-ready', Object.assign({}, currentDimensions, {
      result: 'false'
    }))
  };
}
let metrics = createMetrics();

/**
 * Emit metrics to help find places where apps aren't properly configured to
 * switch over to foundations components
 */
export function emitFoundationsReadinessTracking(themeMissing) {
  try {
    if (!themeMissing && !hasSeenReady) {
      hasSeenReady = true;
      metrics = createMetrics();
    }
    (themeMissing ? metrics.false : metrics.true).increment();
  } catch (_unused) {
    // It's fine, this is very best effort,
    // we just don't want to ever crash an app.
    // If we threw, then this app probably isn't set up though.
  }
}