import devLogger from 'react-utils/devLogger';
import { getDefaultTheme } from '../components/ThemeProvider/ThemeProvider.helpers';
import { emitFoundationsReadinessTracking } from './tracking';
/**
 * An internal function that provides a default theme if the theme
 * is missing or empty. This function is used to ensure that a
 * theme is always available.
 *
 * ```tsx
 * getTheme({ theme: trellisNextTheme })
 * ```
 *
 * @param options - The options for the theme accessor.
 *
 */
export const getTheme = ({
  theme
}) => {
  const isThemeMissing = !theme || Object.keys(theme).length === 0;
  emitFoundationsReadinessTracking(isThemeMissing);
  if (isThemeMissing) {
    devLogger.warn({
      message: 'Failed to load theme. No AppProvider or empty theme found.',
      key: 'foundations-theming:getTheme:theme-load-failure'
    });
    return getDefaultTheme();
  }
  return theme;
};