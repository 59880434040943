import PortalIdParser from 'PortalIdParser';

/* Experiment config template */
// export const GOB000_TREATMENT_KEY = 'gobc-000';
// export const GOB000_PARAMETER_KEY = 'group';
// export const GOB000_PARAMETER_OPTIONS = {
//   CONTROL: 'control',
//   VARIANT1: 'variant',
// };

export const FTS0014_TREATMENT_KEY = 'fts-0014';
export const FTS0014_PARAMETER_KEY = 'group';
export const FTS0014_PARAMETER_OPTIONS = {
  CONTROL: 'control',
  VARIANT: 'variant'
};
export const TREATMENTS_SCHEMAS = {
  [FTS0014_TREATMENT_KEY]: {
    identifier: PortalIdParser.get(),
    parameters: {
      [FTS0014_PARAMETER_KEY]: [FTS0014_PARAMETER_OPTIONS.CONTROL, FTS0014_PARAMETER_OPTIONS.VARIANT]
    }
  }
};