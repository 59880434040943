import { useIsFreePortal } from 'onboarding-scopes';
import { useEffect } from 'react';
import { logExposure } from '../api/experimentsApis';
import { FTS0014_TREATMENT_KEY } from '../constants/experimentConstants';
const ExperimentExposures = () => {
  // EXAMPLE BELOW 👇
  // const isEligibleForOnbc007 = useIsEligibleForOnbc007();

  // useEffect(() => {
  //   if (isEligibleForOnbc007) {
  //     logExposure(ONBC007_TREATMENT_KEY);
  //   }
  // }, [isEligibleForOnbc007]);

  const isEligibleForFts0014 = useIsFreePortal();
  useEffect(() => {
    if (isEligibleForFts0014) {
      logExposure(FTS0014_TREATMENT_KEY);
    }
  }, [isEligibleForFts0014]);
  return null;
};
export default ExperimentExposures;